<template>
  <v-footer
    id="dashboard-core-footer" 
    fixed
    absolute
    max-height="120"
    style="background-color:#1967a9;"
  >
    <v-container>
      <v-row
        align="center"
        no-gutters
      >
        <v-col
          v-for="(link, i) in links"
          :key="i"
          class="text-center mb-sm-0 mb-5"
          cols="auto"
        >   
          <a
            :href="link.href"
            class="mr-0 grey--text text--darken-3"
            rel="noopener"
            target="_blank"
          >
          <v-icon
            color="white darken-2">
            mdi-{{link.text}}
          </v-icon>
          </a>
        </v-col>

        <v-spacer class="hidden-sm-and-down" />

        <v-col
          cols="12"
          md="auto">
          <div class="text-body-1 font-weight-light pt-6 pt-md-0 text-center white--text">
            &copy;  {{ new Date().getFullYear() }} by <a class="white--text" href="https://www.murotech.com.au">MUROtech</a>
            <span v-if="environmentname" class="white--text"> ({{environmentname}})</span>
          </div>
        </v-col>

        <v-col
          class="py-4 text-center white--text"
          cols="12"
          >
          
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
  export default {
    name: 'DashboardCoreFooter',

    data: () => ({
      environmentname: process.env.VUE_APP_ENV_NAME!=='production'?process.env.VUE_APP_ENV_NAME:null,
      links: [
        {
          href: 'https://www.facebook.com/PropCheckAus/',
          text: 'facebook',
        },
        {
          href: 'https://www.instagram.com/propcheckaus',
          text: 'instagram',
        },
        {
          href: 'https://www.linkedin.com/company/propcheckaus',
          text: 'linkedin',
        },
        {
          href: 'https://www.youtube.com/channel/UC5oEBcldZng71QEbR0bxNfw/featured',
          text: 'youtube',
        },
        {
          href: 'https://twitter.com/PropCheckAus',
          text: 'twitter',
        }
      ],
    }),
  }
</script>

<style lang="sass">
  #dashboard-core-footer
    a
      color: #FFF !important
      &:visited 
        color: #FFF !important
      &:hover
        color: #FFF !important
      &:active
        color: #FFF !important 
      font-size: .825rem
      font-weight: 500
      text-decoration: none
      text-transform: uppercase
</style>
